import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import Category from "../components/category";
import SEO from "../components/seo";
import Layout from "../containers/layout";

export const query = graphql`
  query CategoryTemplateQuery($slug: String!) {
    category: sanityCategory(slug: { current: { eq: $slug } }) {
      title
      _rawBody
    }
    projects: allSanitySampleProject(
    filter: {categories: {elemMatch: {slug: {current: {eq: $slug}}}}, active: {eq: true}}
  ) {
    edges {
      node {
        title
        slug {
          current
        }
        mainImage {
          asset {
            _id
          }
        }
        categories {
          slug {
            current
          }
        }
      }
    }
  }
}
  
`;

const CategoryTemplate = (props) => {
  const { data, errors } = props;
  const { category, projects } = data;
  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      {category && <SEO title={category.title || "Untitled"} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {category && <Category data={{ projects: projects, category: category }} />}
    </Layout>
  );
};

export default CategoryTemplate;
