// extracted by mini-css-extract-plugin
export var categories = "category-module--categories--rGi+O";
export var categoriesHeadline = "category-module--categoriesHeadline--yJtGv typography-module--base--FphVn";
export var grid = "category-module--grid--F-Aao";
export var mainContent = "category-module--mainContent--Bq4mp";
export var mainImage = "category-module--mainImage--IcyY3";
export var metaContent = "category-module--metaContent--6WNwR";
export var relatedProjects = "category-module--relatedProjects--az63d";
export var relatedProjectsHeadline = "category-module--relatedProjectsHeadline--XKr9U typography-module--base--FphVn";
export var root = "category-module--root--0XZJz";
export var title = "category-module--title--3Kg4f typography-module--responsiveTitle1--0t0jA";