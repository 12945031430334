import React from "react";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import Container from "./container";
import * as styles from "./category.module.css";
import ProjectPreviewGrid from "../components/project-preview-grid";
import { useItemContext } from "../context/item/context";
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from "../lib/helpers";
import BlockContent from "../components/block-content";
function Category(props) {
  const { category, projects } = props.data;
  const { state, dispatch } = useItemContext();
  const projectNodes = projects.edges && projects.edges && mapEdgesToNodes(projects);
  return (
    <article className={styles.root}>
      <Container>
        <h1 className={styles.title}>{category.title}</h1>
        {category._rawBody && <BlockContent blocks={category._rawBody || []} />}
        {projectNodes && projectNodes.length > 0 ? (
          <ProjectPreviewGrid nodes={projectNodes} />
        ) : (
          "Nincs találat"
        )}
      </Container>
    </article>
  );
}

export default Category;
